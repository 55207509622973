export class BaseError extends Error {
    constructor(message) {
        super(message);
        this.message = message;
        Object.setPrototypeOf(this, BaseError.prototype);
        this.name = this.constructor.name;
    }
}
export class HttpError extends BaseError {
    constructor(message, status, body = null) {
        super(message);
        this.message = message;
        this.status = status;
        this.body = body;
        this.status = status;
    }
}
