import { Cron } from 'croner';

type EventType = {
  playStart: () => void;
  playStop: () => void;
  synchronize: () => void;
}

export default class AppScheduler {
  private static instance: AppScheduler | null = null;
  private stopAndSyncJob: Cron | null = null;
  private startJob: Cron | null = null;
  private event: EventType | null = null;
  
  private constructor() {}

  public static getInstance(): AppScheduler {
    if (this.instance === null) {
      this.instance = new AppScheduler();
    }
    return this.instance;
  }

  private registerStopAndSyncCron() {
    // 0시 10분 00초에 멈추기
    console.log(`[registerStopAndSyncCron]`);
    // this.stopAndSyncJob = new Cron("0 0/2 * * * *", { timezone: 'Asia/Seoul' }, async () => {
    this.stopAndSyncJob = new Cron("0 10 0 * * *", { timezone: 'Asia/Seoul' }, async () => {
      this.event?.playStop();
      console.log(`[play stop]: ${new Date()}`);
      console.log(`[synchronize - start]: ${new Date()}`);
      await this.event?.synchronize();
      console.log(`[synchronize - end]: ${new Date()}`);
      // this.event?.playStart();
    });
  }

  private registerStartCron() {
    // 5시 25분 00초에 시작
    console.log(`[registerStartCron]`);
    this.startJob = new Cron("0 25 5 * * *", { timezone: 'Asia/Seoul' }, () => {
      this.event?.playStart();
      console.log(`[start]: ${new Date()}`);
    });
  }

  public setEvent(event: EventType) {
    // 1번만 Setting 되도록
    if (this.event === null) {
      this.event = event;
      this.registerStopAndSyncCron();
      this.registerStartCron();
    }
  }
}
