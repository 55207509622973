import { z } from 'zod';
const ZodVideoPlaylistTag = z.object({
    id: z.coerce.number().optional(),
    name: z.coerce.string().min(2).max(50),
});
export const ZodCreatPlaylistVideoSchema = z
    .array(z.object({
    videoId: z.coerce.number(),
    playlistOrder: z.coerce.number(),
}))
    .optional();
export const ZodVideoPlayTagList = z.array(ZodVideoPlaylistTag).optional();
export const ZodCreatePlaylist = z.object({
    title: z.coerce.string().min(2).max(50),
    tags: ZodVideoPlayTagList,
    video: ZodCreatPlaylistVideoSchema,
});
export const ZodUpdatePlaylist = z.object({
    title: z.coerce.string().min(2).max(50).optional(),
    tags: ZodVideoPlayTagList,
    video: ZodCreatPlaylistVideoSchema,
});
