import { AxiosError } from 'axios';
import { AppStatusType, BranchEntity, SyncAppEntity, VideoPlaylistEntity } from '@guardianslab/types';

import { createDataProvider, DataProvider } from '../data';
import {
  broswerDirectory,
  writeVideoFile,
  removeFile,
  getVideoFiles,
  createVideoFileName,
  getCreateFileHandle,
  // writeLogFile,
 } from '../../utils/file-systems';
import type { LoginOptions } from './types';
import type { AppVideoFileType } from '../../types';
import { removeDataFromStorage, getDataFromStorage, saveDataToStorage } from '../localStorage';
import { AppFileHandle } from '../fileHandle';
import { AppLog } from '../log';

  export class AppProviderClass {
    private readonly dataProvider: DataProvider;
    private appFileHandle: AppFileHandle | undefined = undefined;
    private fileSystemDirectoryHandle: FileSystemDirectoryHandle | undefined = undefined;
    private token: string | undefined = undefined;

    private authenticated: boolean = false;
    private appState: AppStatusType | undefined = undefined;
    private playlist: VideoPlaylistEntity | undefined = undefined;
    private syncApp: SyncAppEntity | undefined = undefined;
    private branch: BranchEntity | undefined = undefined;

    private isVideoLogUploading = false;
    
    constructor(dataProvider: DataProvider) {
      this.dataProvider = dataProvider;
    }
  
    private async checkAuthentiateApp(token: string, appState?: AppStatusType) {
      try {
        const data = await this.dataProvider.checkApp(token);
        this.setAuthenticated(data.active);
        this.setAppState(data);
        
        return true;
      } catch(error: unknown) {
        if (error instanceof AxiosError) {
          // console.log(`[checkAuthentiateApp] - ${JSON.stringify(error)}`);
          if (error.code === 'ECONNABORTED') {
            // offline ??
            if (appState) {
              this.setAuthenticated(appState.active);
              this.setAppState(appState);
              return true;
            }
          } else if (error.response?.status === 400) {
            this.setAuthenticated(false);
          }
        }
        return false;
      }
    }

    private async getSyncApp(syncAppId: number): Promise<SyncAppEntity> {
      return await this.dataProvider.getSyncApp(this.token || '', syncAppId);
    }

    private async getPlaylist(playlistId: number): Promise<VideoPlaylistEntity> {
      return await this.dataProvider.getPlaylist(this.token || '', playlistId);
    }

    private async getBranch(branchId: number): Promise<BranchEntity> {
      return await this.dataProvider.getBranch(this.token || '', branchId);
    }

    // private async getStatus() {
    //   if (this.token) {
    //     const data = await this.dataProvider.checkApp(this.token);
    //   }
    // }
  
    private setAuthenticated(authenticated: boolean) {
      this.authenticated = authenticated;
      if (authenticated === false) {
        removeDataFromStorage('token');
      }
    }
  
    private setAppState(data: AppStatusType | undefined) {
      this.appState = data;
      if (data) {
        saveDataToStorage('appStatus', data);
      } else {
        removeDataFromStorage('appStatus');
      }
    }
  
    private getToken() {
      if (this.token) {
        return this.token;
      }
      this.token = getDataFromStorage('token') as string;
      return this.token;
    }
  
    private saveToken(token: string): void {
      saveDataToStorage('token', token);
    }
   
    private async reset() {
      this.setAuthenticated(false);
      this.setAppState(undefined);

      if (this.fileSystemDirectoryHandle) {
        const localFiles = await this.getLocalVideoFiles(this.fileSystemDirectoryHandle);
        await this.removeAllFiles(this.fileSystemDirectoryHandle, localFiles);
      }
    }

    getAppState(): AppStatusType | undefined {
      if (this.appState) {
        return this.appState;
      }
      this.appState = getDataFromStorage('appStatus') as AppStatusType;
      return this.appState;
    }

    getAppStateFetchSync(): boolean {
      const state = this.getAppState();
      return state ? (state.sync === true ? false : true) : true;
    }
  
    async init(): Promise<{
      playlist: VideoPlaylistEntity | undefined;
      syncApp: SyncAppEntity | undefined;
      branch: BranchEntity | undefined;
    }> {
      const data = this.getAppState();
      if (data?.playlistId) {
        this.playlist = await this.getPlaylist(data.playlistId);
      }
      if (data?.syncAppId) {
        this.syncApp = await this.getSyncApp(data.syncAppId);
      }
      if (data?.branchId) {
        this.branch = await this.getBranch(data.branchId);
      }
      
      return {
        playlist: this.playlist,
        syncApp: this.syncApp,
        branch: this.branch,
      }
    }

    async setFileSystemDirectoryHandle(fileSystemDirectoryHandle: FileSystemDirectoryHandle) {
      this.fileSystemDirectoryHandle = fileSystemDirectoryHandle;
      this.appFileHandle = new AppFileHandle(fileSystemDirectoryHandle);
    }

    async doAuthenticate() {
      // let success = false;
      const token = this.getToken();
      const appState = this.getAppState();
      // TODO - offlinet 일 경우
      // console.log(`[doAuthenticate]: ${token}`);
      if (token) {
        const success = await this.checkAuthentiateApp(token, appState);
        if (success) {
          this.init();
        }
      }
    }

    async uploadLogFiles(logFiles: File[]) {
      try {
        const token = this.getToken();
        await logFiles.reduce(async (acc, file) => {
          return acc.then(async () => {
            await this.dataProvider.s3FileUpload(token, 'presigned-video-log', file);
            await this.appFileHandle?.removeVideoLogFile(file.name);
          })
        }, Promise.resolve());
      } catch (error: unknown) {
        console.log(error);
        // AppLog.addError(`[writeVideoLogAndUpload] - ${JSON.stringify(error)}`);
      }
      
    }

    async writeVideoLogAndUpload(logs: any[]): Promise<void> {
      try {
        if (this.isVideoLogUploading === false) {
          this.isVideoLogUploading = true;
          const file = await this.appFileHandle?.writeVideoLog(logs);
          if (file) {
            const token = this.getToken();
            await this.dataProvider.s3FileUpload(token, 'presigned-video-log', file);
            await this.appFileHandle?.removeVideoLogFile(file.name);
          }
          this.isVideoLogUploading = false;
        }
      } catch (error: unknown) {
        AppLog.addError(`[writeVideoLogAndUpload] - ${JSON.stringify(error)}`);
      }
    }

    async getDownloadVideos(fileSystemDirectoryHandle: FileSystemDirectoryHandle): Promise<void> {
      const token = this.getToken();
      if (token) {
        const downloadUrls = await this.dataProvider.getDownloadVideoUrls(token);

        const downloadAndWrite = downloadUrls.map(async (download) => {
          const videoFileHandle = await getCreateFileHandle(fileSystemDirectoryHandle, createVideoFileName(download.id, download.title, download.type));
          await writeVideoFile(videoFileHandle, this.dataProvider.fileDownload(download.url));
        });
        await Promise.all(downloadAndWrite);
      }
    }

    async getDownloadVideoId(fileSystemDirectoryHandle: FileSystemDirectoryHandle, videoId: number | string): Promise<void> {
      const token = this.getToken();
      if (token) {
        const { id, url, title, type } = await this.dataProvider.getDownloadVideoIdUrl(token, videoId);

        const videoFileHandle = await getCreateFileHandle(fileSystemDirectoryHandle, createVideoFileName(id, title, type));
        await writeVideoFile(videoFileHandle, this.dataProvider.fileDownload(url));
      }
    }

    async removeAllFiles(fileSystemDirectoryHandle: FileSystemDirectoryHandle, files: File[]): Promise<void> {
      await Promise.all(files.map(async (file) => {
        await removeFile(fileSystemDirectoryHandle, file.name);
      }));
    }

    async getLocalVideoFiles(fileSystemDirectoryHandle: FileSystemDirectoryHandle): Promise<File[]> {
      const directory = await broswerDirectory(fileSystemDirectoryHandle);
      const videoFiles = await getVideoFiles(directory);
      return videoFiles;
    }
  
    isAuthenticated(): boolean {
      return this.authenticated;
    }
  
    async login(data: LoginOptions): Promise<void> {
      const token = await this.dataProvider.chcekPasswordAndGetToken(data);
      const auth = window.btoa(`${data.appId}:${token}`);
      this.saveToken(auth);
      await this.doAuthenticate();
    }
  
    unRegisterApp() {
      this.reset();
    }

    async updateAppSync(syncAppId: number, version: number): Promise<void> {
      return await this.dataProvider.updateAppSync(this.token || '', syncAppId, version);
    }
  }
  
  const createAppProvider = (apiUrl: string): AppProviderClass => {
    const dataProvider = createDataProvider(apiUrl);
    const instance = new AppProviderClass(dataProvider);
    return instance;
  };
  
  export default createAppProvider;
  
  