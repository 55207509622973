  import { AppProviderClass } from '../app';
import type { AppVideoFileType } from '../../types';
import { LocalVideoFileStatus } from '../../constants';
import {
  broswerDirectory,
  getVideoFiles,
  getVideoLogFiles,
  removeFile,
 } from '../../utils/file-systems';
import { getVideoFileList } from '../../reducers/app/utils'; 

export default class FileSyncronizer {
  private static instance: FileSyncronizer | null = null;
  
  private constructor(private appProvider: AppProviderClass, private fileSystemDirectoryHandle: FileSystemDirectoryHandle) {}

  public static getInstance(appProvider: AppProviderClass, fileSystemDirectoryHandle: FileSystemDirectoryHandle): FileSyncronizer {
    if (this.instance === null) {
      this.instance = new FileSyncronizer(appProvider, fileSystemDirectoryHandle);
    }
    return this.instance;
  }

  private filterUnRegistredVideoFiles(videoFiles: AppVideoFileType[]) {
    const unRegistredVideoFiles = videoFiles.filter((video) => video.flag === LocalVideoFileStatus.unregistered);
    return unRegistredVideoFiles;
  }

  private async getLocalVideoFiles() {
    const directory = await broswerDirectory(this.fileSystemDirectoryHandle);
    const videoFiles = await getVideoFiles(directory);
    return videoFiles;
  }

  private async getLocalVideoLogFiles() {
    const directory = await broswerDirectory(this.fileSystemDirectoryHandle);
    const logFiles = await getVideoLogFiles(directory);
    return logFiles;
  }

  private async removeVideoFiles(videoFiles: AppVideoFileType[]): Promise<void> {
    await Promise.all(videoFiles.map(async (videoFile) => {
      const { file } = videoFile;
      if (file) {
        await removeFile(this.fileSystemDirectoryHandle, file.name);
      }
    }));
  }

  private async uplodLogFiles() {
    const logFiles = await this.getLocalVideoLogFiles();
    await this.appProvider.uploadLogFiles(logFiles)
  }

  public async syncronize() {
    try {
      await this.uplodLogFiles();
      // TODO
      // 일단은 playlist를 무조건 가져와서 Sync 체크하는 식으로 구현!
      // 추후에 sync false 일때 하도록
      // const { syncAppId, version, sync } = this.appProvider.getAppState() || {};
      const { playlist } = await this.appProvider.init();

      // console.log(`[before]: syncAppId: ${syncAppId}, version: ${version}, sync: ${sync}`);
      // console.log(updateSyncApp);
      // console.log(`[after]: syncAppId: ${updateSyncApp?.id}: version: ${updateSyncApp?.version}, sync: ${updateSyncApp?.sync}`)
      // if (syncApp?.version !== version) {

      // }

      // const isNeedUpdate = version !== updateSyncApp?.version;
      // console.log(`isNeedUpdate: ${isNeedUpdate}`);
      // let isNeedUpdate = false;

      if (playlist?.video) {
        const localVideoFiles = await this.getLocalVideoFiles();
        const videoFilesWithPlaylistVideo = getVideoFileList(playlist?.video?.slice(), localVideoFiles);

        // remove unRegistred Video Files
        const unRegistredVideoFiles = this.filterUnRegistredVideoFiles(videoFilesWithPlaylistVideo);
        if (unRegistredVideoFiles.length > 0) {
          await this.removeVideoFiles(unRegistredVideoFiles);
        }

        // download Files
        const unDownloadedVideoFiels = videoFilesWithPlaylistVideo.filter((video) => video.flag === LocalVideoFileStatus.undownloaded && video.videoId);
        if (unDownloadedVideoFiels.length) {
          // isNeedUpdate = true;
          await unDownloadedVideoFiels.reduce(async (acc, current) => {
            return acc.then(() => {
              if (current && current.videoId) {
                return this.appProvider.getDownloadVideoId(this.fileSystemDirectoryHandle, current.videoId);
              }  
            })
          }, Promise.resolve());
        }
      }

      // 현재는 재생목록 순서가 바뀌는 경우는 일단 Update 안됨..
      // if (isNeedUpdate && updateSyncApp) {
      //   console.log(updateSyncApp);
      //   await this.appProvider.updateAppSync(
      //     updateSyncApp.id,
      //     updateSyncApp.version,
      //   );
      // }
      

      return {
        success: true,
        data: {
          playlist,
          files: await this.getLocalVideoFiles(),
        }
      }
    } catch (error: unknown) {
      console.error(error);
      return {
        success: false,
      }
    }
  }
}