import { z } from 'zod';
export const ZodLogin = z.object({
    email: z.string().email(),
    password: z.string(),
}).strict();
export const ZodForceUpdatePasswordInput = z.object({
    confirmPassword: z.string(),
    newPassword: z.string(),
}).strict();
export const ZodForceUpdatePassword = z.object({
    password: z.string(),
    username: z.string(),
    session: z.string(),
}).strict();
export const ZodRefreshToken = z.object({
    username: z.string(),
    refreshToken: z.string(),
}).strict();
