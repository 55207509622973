import { z } from 'zod';
export const ZodPrismaStringQueryObject = z.object({
    equals: z.string().optional(),
    in: z.string().array().optional(),
    notIn: z.string().array().optional(),
    lt: z.string().optional(),
    lte: z.string().optional(),
    gt: z.string().optional(),
    gte: z.string().optional(),
    contains: z.string().optional(),
    startsWith: z.string().optional(),
    endsWith: z.string().optional(),
    not: z.string().optional(),
});
export const ZodPrismaNumberQueryObject = z.object({
    equals: z.coerce.number().optional(),
    in: z.coerce.number().array().optional(),
    notIn: z.coerce.number().array().optional(),
    lt: z.coerce.number().optional(),
    lte: z.coerce.number().optional(),
    gt: z.coerce.number().optional(),
    gte: z.coerce.number().optional(),
    contains: z.coerce.number().optional(),
    startsWith: z.coerce.number().optional(),
    endsWith: z.coerce.number().optional(),
    not: z.coerce.number().optional(),
});
