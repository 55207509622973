import type { AppStateType } from './types';
import type { AppActionType } from '../../actions/app';
import { AppStatusActionDefinitions } from '../../actions/app';
import { videoLogUploadUnit } from '../../environments';
import { getVideoFileList, getPlaylistVideo, checkNeedSync } from './utils';

export function appStateReducer(state: AppStateType, action: AppActionType): AppStateType {
  if (action.type === AppStatusActionDefinitions.TYPE_LOADED) {
    const {
      loaded,
      playlist,
      branch,
      syncApp,
    } = action.value;
    const {
      files 
    } = state;

    let allVideoList = undefined;
    let videoPlaylist = undefined;
    let needSync = false;

    if (playlist && playlist.video) {
      allVideoList = getVideoFileList(playlist.video.slice(), files.slice());
      videoPlaylist = getPlaylistVideo(allVideoList);
      needSync = checkNeedSync(allVideoList);
    }

    return {
      ...state,
      needSync,
      loaded,
      playlist,
      branch,
      syncApp,
      allVideoList,
      videoPlaylist,
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_LOADING) {
    const {
      loading,
    } = action.value;

    return {
      ...state,
      loading
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_PLAYING) {
    if (action.value === false) {
      return {
        ...state,
        playing: action.value
      };
    }

    return {
      ...state,
      playing: action.value,
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_ADD_LOG) {
    const updateLogs = [
      ...state.logs,
      action.value,
    ];
    if (updateLogs.length > videoLogUploadUnit) {
      return {
        ...state,
        logs: [],
        uploadLogs: updateLogs.slice(),
      };
    }
    return {
      ...state,
      logs: [
        ...state.logs,
        action.value,
      ],
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_CLEAR_UPLOAD_LOG) {
      return {
        ...state,
        uploadLogs: [],
      };
  } else if (action.type === AppStatusActionDefinitions.TYPE_SYNCHRONIZING) {
    const synchronizing = action.value;

    return {
      ...state,
      synchronizing: synchronizing,
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_SET_VIDEOFILES) {
    const { files = [], playlist } = action.value;

    let allVideoList = undefined;
    let videoPlaylist = undefined;
    let needSync = false;

    if (playlist && playlist.video) {
      allVideoList = getVideoFileList(playlist.video.slice(), files.slice());
      videoPlaylist = getPlaylistVideo(allVideoList);
      needSync = checkNeedSync(allVideoList);
    }

    return {
      ...state,
      files,
      allVideoList,
      videoPlaylist,
      needSync,
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_APPOUT) {
    return {
      ...state,
      appOut: action.value
    };
  } else if (action.type === AppStatusActionDefinitions.TYPE_SET_ERROR) {
    return {
      ...state,
      error: action.value,
    };
  } 

  return state;
}


