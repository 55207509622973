export const VideoStatus = {
  start: 'start',
  end: 'end',
  pause: 'pause',
  abort: 'abort',
  error: 'error'
} as const;

export const LocalVideoFileStatus = {
  downloaded: 'downloaded',
  undownloaded: 'undownloaded',
  unregistered: 'unregistered',
  registered: 'registered',
} as const;

export const LOCAL_FILE_PREFIX = 'CMS';
export const VIDEO_LOGFILE_PREFIX = 'video-logs';

