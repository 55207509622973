import { VIDEO_LOGFILE_PREFIX } from '../../constants';

export default class AppFileHandle {
  constructor(private fileSystemDirectoryHandle: FileSystemDirectoryHandle) {}

  private async createFile(fileName: string): Promise<FileSystemFileHandle> {
    return this.fileSystemDirectoryHandle.getFileHandle(fileName, { create: true });
  }

  async writeVideoLog(logs: any[]): Promise<File> {
    const currentTime = Date.now();

    const fileHandle = await this.createFile(`${VIDEO_LOGFILE_PREFIX}_${currentTime}`);
    const writable = await fileHandle.createWritable();
    await writable.write(JSON.stringify(logs));
    await writable.close();
    const file = await fileHandle.getFile();

    return file;
  }

  async removeVideoLogFile(name: string): Promise<void> {
    await this.fileSystemDirectoryHandle.removeEntry(name);
  }
}